import { InjectionKey, inject } from "vue";
import { Moment } from "moment";
import { omit, assign } from "lodash";

import { TableItem, TableGroup } from "../../types/";

declare const Buffer: any;

export async function asyncForEach<T>(
  array: Array<T>,
  callback: (value: T, index: number, array: Array<T>) => void
) {
  for (let index = 0; index < array.length; index++) {
    try {
      await callback(array[index], index, array);
    } catch {
      return;
    }
  }
}

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`);
  }
  return resolved;
}

export const getDateFromPesel = (pesel: string): Date => {
  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10);
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month >= 20) {
    year += 2000;
    month = month - 20;
  } else {
    year += 1900;
  }

  return new Date(year, month - 1, day);
};

export const isValidPesel = (pesel: string): boolean => {
  if (!pesel) {
    return false;
  }

  const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let sum = 0;
  const controlNumber = parseInt(pesel.substring(10, 11));
  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;
  return sum === controlNumber;
};

export const isValidNip = (nip: string): boolean => {
  if (!nip || nip.length !== 10) {
    return false;
  }
  if (typeof nip !== "string") return false;

  nip = nip.replace(/[\\-]/gi, "");

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(nip.substring(i, i + 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const isValidPostcode = (code: string): boolean => {
  if (!code) return false;
  const format = code.split("-");
  if (format && format.length == 2) {
    if (format[0].length == 2) {
      if (format[1].length == 3) {
        if (/^\d+$/.test(format[0]) && /^\d+$/.test(format[1])) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }
  return false;
};

export const deepIterate = function <T>(
  obj: T,
  cb: (key: Array<string>, value: T[keyof T]) => void,
  keys: Array<string> = []
) {
  Object.keys(obj as any).forEach((key) => {
    if (
      typeof (obj as unknown as { [index: string]: T[keyof T] })[key] ===
        "object" &&
      !((obj as unknown as { [index: string]: T[keyof T] })[key] === null)
    ) {
      return deepIterate<any>(
        (obj as unknown as { [index: string]: T[keyof T] })[key],
        cb,
        keys.concat([key])
      );
    } else {
      cb(
        keys.concat([key]),
        (obj as unknown as { [index: string]: T[keyof T] })[key]
      );
    }
  });
};

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export class TextToBase64 {
  static encrypt(text: string) {
    return Buffer.from(text, "binary").toString("base64");
  }
  static decrypt(text: string) {
    return Buffer.from(text, "base64").toString();
  }
}

export class CheckUserAgent {
  private static chromeAgent: boolean =
    navigator.userAgent.indexOf("Chrome") > -1;
  private static IExplorerAgent: boolean =
    navigator.userAgent.indexOf("MSIE") > -1;
  private static firefoxAgent: boolean =
    navigator.userAgent.indexOf("Firefox") > -1;
  private static safariAgent: boolean =
    navigator.userAgent.indexOf("Safari") > -1;
  private static operaMobileAgent: boolean =
    navigator.userAgent.indexOf("OPT") > -1;
  private static operaAgent: boolean = navigator.userAgent.indexOf("OPR") > -1;

  static isSafari() {
    this.chromeAgent && this.safariAgent && (this.safariAgent = false);
    return this.safariAgent;
  }
  static isChrome() {
    return this.chromeAgent;
  }
  static isInternetExplorer() {
    return this.IExplorerAgent;
  }
  static isFireFox() {
    return this.firefoxAgent;
  }
  static isOperaMobile() {
    return this.operaMobileAgent;
  }
  static isOpera() {
    return this.operaAgent;
  }
}

export const snakeCaseToCamelCase = (val: string) => {
  return val
    .toLocaleLowerCase()
    .replaceAll(/(_)[a-z]/g, function (letter) {
      return letter.toUpperCase();
    })
    .replaceAll("_", "");
};

export function createGroupTableRecords(
  response: unknown,
  cloneParent = true,
  key = "items"
): Array<TableGroup> {
  if (Array.isArray(response)) {
    let counterParent = 0;
    const tableGroupProcessed: Array<TableGroup> = [];
    response.map((parent: TableGroup) => {
      const tableGroupExtTmp: TableGroup = {};
      assign(tableGroupExtTmp, {
        dataIndex: counterParent,
        children: [],
        ...omit(parent, [key]),
      });
      if (
        parent[key] &&
        Array.isArray(parent[key]) &&
        tableGroupExtTmp.children
      ) {
        let counterChild = 0;
        (parent[key] as TableItem[]).map((child: TableItem) => {
          let cloneObj = { dataIndex: `${counterParent}.${counterChild}` };
          if (cloneParent) {
            cloneObj = {
              ...cloneObj,
              ...omit(parent, [key]),
            };
          }
          assign(child, cloneObj);
          (tableGroupExtTmp.children as Array<TableItem>).push(child);
          counterChild++;
        });
      }
      tableGroupProcessed.push(tableGroupExtTmp);
      counterParent++;
    });
    return tableGroupProcessed;
  } else {
    return [];
  }
}

export async function isObjectStringsEmpty(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  isEmpty = true,
  isBreak = false,
  exclude = [""]
) {
  for (const property in obj) {
    if (typeof obj[property] === "object" && obj[property] !== null) {
      [isEmpty, isBreak] = await isObjectStringsEmpty(
        obj[property],
        isEmpty,
        isBreak,
        exclude
      );
      if (isBreak) break;
    }
    if (
      !(typeof obj[property] === "object") &&
      typeof obj[property] === "string" &&
      obj[property].length > 0 &&
      exclude.indexOf(property.trim()) == -1
    ) {
      isEmpty = false;
      isBreak = true;
      break;
    }
  }
  return [isEmpty, isBreak];
}

export const isPersonAdultByDate = (peselDate: Date): boolean => {
  const peselDatePlus18 = new Date(peselDate.getTime());
  peselDatePlus18.setFullYear(peselDatePlus18.getFullYear() + 18);
  if (new Date().getTime() >= peselDatePlus18.getTime()) {
    return true;
  } else {
    return false;
  }
};
export const wasThePatientBorn = (peselDate: Date): boolean => {
  if (new Date().getTime() >= peselDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

export const getDate = (moment: Moment) => {
  return `${moment.date()}.${moment.month()}.${moment.year()}`;
};
